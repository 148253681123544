import React from 'react';
import Template from '../components/template';
import '../styles/reset.css';
import '../styles/global.css';

export default function Home() {
  return (
    <Template />
  );
}
